import React, { useState, useCallback, useEffect } from 'react';
import { ArrowLeftIcon, ArrowRightIcon, XCircleIcon } from '@heroicons/react/outline';
import Input from 'react-phone-number-input/input'
import JSConfetti from 'js-confetti';
import { useRouter } from 'next/router'
import Link from 'next/link'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

export default function Step4({ prevStep, nextStep, form, updateFieldValue, source, agree, dataArray, setSuccess, ...FormFields }) {

  const [results, setResults] = React.useState(false)

  const errorObj = Object.keys(FormFields).reduce((prev, curr) => (prev[curr] = false, prev), {})

  //create an object of key value pair set to false each with a key === names of all basic form field keys
  const [errors, setErrors] = useState({ ...errorObj })

  const [haveEmail, setHaveEmail] = useState(true)


  const handleEmailToggle = (value) => {
    setHaveEmail(value)
    if (value === false) {
      updateFieldValue("email_address", "")
    }
  }

  const [phone, setPhone] = useState(FormFields.phone_number);

  const [ip, setIp] = useState()

  const getIp = async () => {
    // Connect ipapi.co with fetch()
    const response = await fetch('https://ipapi.co/json/')
    const data = await response.json()
    // Set the IP address to the constant `ip`
    setIp(data.ip)
  }

  // Run `getIP` function above just once when the page is rendered
  useEffect(() => {
    getIp()
  }, [])


  const handlePhone = (value) => {
    updateFieldValue("phone_number", value.replace("+", ''))
    setPhone(value)
    console.log("phone", value)
  }

  const validEmail = new RegExp(
    '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z ]$'
  );

  const validFormFields = () => {
    let updateErrorObj = { ...errors };
    Object.entries(FormFields).some(([key, value]) => {
      if (key === "email_address") {
        updateErrorObj[key] = false
      } else if (key === 'phone_number') {
        if (!phone || phone.replace("+", '').length !== 11) {
          updateErrorObj[key] = true
        } else {
          updateErrorObj[key] = false
        }

      } else if (!value) {
        updateErrorObj[key] = true;
        // console.log("error", key, value)
      } else {
        updateErrorObj[key] = false
      }
    })
    setErrors(updateErrorObj)
    return (Object.values(updateErrorObj).includes(true)) ? false : true
  }


  // const handleNextStep = () => {
  //   if (validFormFields()) {
  //     // if no errors...

  //     try {
  //       nextStep(3)
  //       updateFieldValue("phone_number", phone.replace("+", ''))
  //       //post to a db
  //     } catch (error) {
  //       console.error('error', error);
  //     } finally {
  //     }
  //   }
  // }

  const router = useRouter()
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [agreeErr, setAgreeErr] = useState(false);
  const [loading, setLoading] = useState(false)

  const notifyWebhook = async (url, body) => {
    // console.log("POST", url, body)
    return fetch(url, {
      mode: 'no-cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
  }

  const date = new Date();

  const pst = date.toLocaleString('en-US', {
    timeZone: 'America/Los_Angeles',
  });


  const handleSubmitCallback = useCallback(() => {
    setLoading(true)
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      setLoading(false)
      return;
    }
    executeRecaptcha("contactFormSubmit").then((gReCaptchaToken) => {
      console.log(gReCaptchaToken, "response Google reCaptcha server");
      if (!document.querySelector("#leadid_token")?.getAttribute('value')) {
        console.log("no leadid token")
        setTimeout(handleSubmitCallback, 4000) //four seconds
      } else {
        try {
          setAgreeErr(false)
          setLoading(true)
          nextStep(3)

          //add timestamp
          dataArray['time_stamp'] = pst + " PST"
          dataArray['url'] = `https://thelegalcanary.com${router.asPath}`
          dataArray["leadid_token"] = document.querySelector("#leadid_token")?.getAttribute('value')
          dataArray['ip'] = ip
          // dataArray['did'] = did

          //use zapier
          notifyWebhook(source.includes("Craftwise") ? "https://hooks.zapier.com/hooks/catch/13095819/bcnudmq/" : "", dataArray)
          // console.log("data", dataArray)
          // jsConfetti.addConfetti()
          setSuccess(true)
          setLoading(false)
          router.push("/confirmation/success")


        } catch (error) {
          console.error('error', error);
        } finally {

        }
      }
    });
  },
    [dataArray]
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validFormFields() && agree) {
      // if no errors...

      handleSubmitCallback()
    } else {
      setAgreeErr(true)
      setLoading(false)
    }
  }


  if (!results) {
    return (
      <div id={`${form}Step4`}>
        <p className="mb-5 block text-xl md:text-2xl font-bold text-black">
          How can we reach you?
        </p>
        <p>Please provide the following information so we can contact you.</p>
        <div className="grid grid-cols-12 gap-6">

          <div className="col-span-12 sm:col-span-6">
            <label htmlFor="first-name" className="text-left block text-sm font-medium text-gray-700">
              First Name
            </label>
            <input
              type="text"
              name="first_name"
              id="first_name"
              value={FormFields.first_name}
              autoComplete="given-name"
              onChange={(event) => updateFieldValue(event.target.name, event.target.value)}
              className={`text-gray-700 mt-1 h-10 block w-full rounded-md ${errors.first_name ? "border-red-500" : "border-gray-300"} shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm`}
            />
            {errors.first_name &&
              <p className="mt-2 mb-0 text-xs text-red-500 flex col-span-12">
                <XCircleIcon className="h-4 w-4 mr-1" /> Please enter a valid name.
              </p>
            }
          </div>

          <div className="col-span-12 sm:col-span-6">
            <label htmlFor="last-name" className="text-left block text-sm font-medium text-gray-700">
              Last Name
            </label>
            <input
              type="text"
              name="last_name"
              id="last_name"
              value={FormFields.last_name}
              autoComplete="family-name"
              onChange={(event) => updateFieldValue(event.target.name, event.target.value)}
              className={`text-gray-700 mt-1 h-10 block w-full rounded-md ${errors.first_name ? "border-red-500" : "border-gray-300"} shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm`}
            />
            {errors.last_name &&
              <p className="mt-2 mb-0 text-xs text-red-500 flex col-span-12">
                <XCircleIcon className="h-4 w-4 mr-1" /> Please enter a valid name.
              </p>
            }
          </div>
          <div className="col-span-12">
            <label htmlFor="phone-number" className="text-left block text-sm font-medium text-gray-700">
              Phone Number
            </label>
            <div className="mt-1 flex items-center">
              <Input
                defaultCountry="US"
                country="US"
                value={phone}
                // onChange={setPhone}
                onChange={handlePhone}
                className={`mt-1 h-10 text-gray-700 bg-white px-2 border block w-full rounded-md ${errors.phone_number ? "border-red-500" : "border-gray-300"} shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm`}
              />
            </div>
            {errors.phone_number &&
              <p className="mt-2 mb-0 text-xs text-red-500 flex col-span-12">
                <XCircleIcon className="h-4 w-4 mr-1" /> Please enter a valid phone number.
              </p>
            }
          </div>
          <div className="col-span-12">
            {haveEmail &&
              <div className="col-span-12">
                <label htmlFor="email-address" className="text-left block text-sm font-medium text-gray-700">
                  Email Address
                </label>
                <input
                  type="text"
                  name="email_address"
                  id="email_address"
                  value={FormFields.email_address}
                  autoComplete="email"
                  onChange={(event) => updateFieldValue(event.target.name, event.target.value)}
                  className={`text-gray-700 mt-1 h-10 block w-full rounded-md ${errors.email_address ? "border-red-500" : "border-gray-300"} shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm`}
                />
                {errors.email_address &&
                  <p className="mt-2 mb-0 text-xs text-red-500 flex col-span-12">
                    <XCircleIcon className="h-4 w-4 mr-1" /> Please enter a valid email address.
                  </p>
                }
              </div>
            }

            {/* <div className="flex items-start mt-5">
              <div className="flex h-5 items-center">
                <input
                  id="have_email"
                  name="have_email"
                  type="checkbox"
                  className="h-5 w-5 rounded border-gray-300 text-teal-600 focus:ring-teal-500"
                  checked={!haveEmail}
                  onClick={(event) => handleEmailToggle(!event.target.checked)}

                />
              </div>
              <div className="ml-3 text-base">
                <label htmlFor="have_email" className="block text-sm font-medium text-gray-700">
                  I don&apos;t have an email address
                </label>
              </div>
            </div> */}
          </div>

        </div>
        <div className="mt-10 text-right">
          <div className="col-span-12 flex items-start">
            <input
              id="not_coached_compensated"
              name="not_coached_compensated"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-teal-600 focus:ring-teal-600"
              checked={dataArray.not_coached_compensated}
              onChange={(event) => updateFieldValue("not_coached_compensated", event.target.checked)}
            />
            <label htmlFor="not_coached_compensated" className="ml-2 block text-sm text-gray-600">
              I confirm that I was not coached and I was not offered any compensation for this claim.
            </label>
          </div>
          {errors.not_coached_compensated &&
            <p className="mt-2 mb-0 text-xs text-red-500 flex col-span-12">
              <XCircleIcon className="h-4 w-4 mr-1" /> Please agree to the terms and conditions above to proceed.
            </p>
          }

          <div className="col-span-12 flex items-start">
            <input
              id="agree_provide_records"
              name="agree_provide_records"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-teal-600 focus:ring-teal-600"
              checked={dataArray.agree_provide_records}
              onChange={(event) => updateFieldValue("agree_provide_records", event.target.checked)}
            />
            <label htmlFor="agree_provide_records" className="ml-2 block text-sm text-gray-600">
              I understand I will be asked for medical records, and if falsified, I will be held liable
              for knowingly providing false information and the Law Firm reserves the right to seek all available remedies under the law.
            </label>
          </div>
          {errors.agree_provide_record &&
            <p className="mt-2 mb-0 text-xs text-red-500 flex col-span-12">
              <XCircleIcon className="h-4 w-4 mr-1" /> Please agree to the terms and conditions above to proceed.
            </p>
          }

          <input id="leadid_token" name="universal_leadid" className="col-span-12" value="" type="hidden" />

          <div className="col-span-12 flex items-start">
            <input
              id="leadid_tcpa_disclosure"
              name="leadid_tcpa_disclosure"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-teal-600 focus:ring-teal-600"
              checked={dataArray.agree}
              onChange={(event) => updateFieldValue("agree", event.target.checked)}
            />
            <label htmlFor="leadid_tcpa_disclosure" className="ml-2 block text-sm text-gray-600">
              I understand that by submitting my request, I confirm that I have provided my legal name and my own phone number
              and I&apos;ve read & agreed to the <Link href="/privacy-policy"><a className='mx-1 underline'>Privacy Policy</a></Link> and
              <Link href="/terms-conditions"><a className='mx-1 underline'>Terms &amp; Conditions</a></Link> of this site and that I gave my consent to
              be contacted from thelegalcanary.com, and its  <Link href="/partners"><a className='mx-1 underline'>Partners</a></Link> by email or at the phone number I entered using automated
              technology including recurring auto-dialers, pre-recorded messages, and text messages, even if my phone
              number is a mobile number or is currently listed on any state, federal, or corporate &quot;Do Not Call&quot; list.
              I understand there may be a charge from my wireless carrier for such communications.
              Your accurate information is required for a free evaluation. To unsubscribe at any time, reply &quot;STOP&quot;. Message &amp; Data rates may apply
            </label>
          </div>
          {errors.agree &&
            <p className="mt-2 mb-0 text-xs text-red-500 flex col-span-12">
              <XCircleIcon className="h-4 w-4 mr-1" /> Please agree to the terms and conditions above to proceed.
            </p>
          }

          {/* <button
            onClick={() => prevStep(4)}
            className="
                            inline-flex 
                            items-center 
                            justify-center
                            py-1
                            px-2 
                            mt-5
                            w-full
                            uppercase
                            text-medium
                            font-medium 
                            tracking-widest
                            text-black hover:text-gray-800
                            focus:outline-none 
                            focus:ring-2 
                            focus:ring-offset-2 
                            focus:ring-blue-500"
          >
            <ArrowLeftIcon className='mr-2 h-4 w-4' /> Back
          </button> */}
        </div>
        <div className="mt-10 text-right">
          <button
            disabled={loading}
            onClick={(event) => handleSubmit(event)}
            type="submit"
            className={`transition 
                                ease-in-out 
                                delay-150 
                               
                                text-lg
                                md:text-xl
                                cursor-pointer
                                w-full
                                group
                                mb-2
                               ${loading ? "bg-gray-200 text-gray-500" : "text-white hover:-translate-y-1 hover:drop-shadow-xl bg-teal-500 hover:bg-teal-600"}
                                duration-300 
                                px-6 py-4
                                drop-shadow-lg 
                                tracking-widest
                                rounded-sm 
                                flex 
                                font-extrabold 
                                uppercase 
                                items-center 
                                justify-center`}>
            {loading ? "Loading..." : "Talk to a Lawyer Now"}
          </button>
        </div>
        {/* <div className="grid grid-cols-2 gap-2 mt-10 text-right">
          <button
            onClick={() => prevStep(3)}
            className="
                            inline-flex 
                            items-center 
                            justify-center
                            py-1
                            px-2 
                            w-full
                            shadow-sm 
                            uppercase
                            text-medium
                            font-medium 
                            tracking-widest
                            text-black border border-black hover:text-white hover:bg-gray-900
                            focus:outline-none 
                            focus:ring-2 
                            focus:ring-offset-2 
                            focus:ring-blue-500"
          >
            <ArrowLeftIcon className='mr-2 h-4 w-4' /> Back
          </button>
          <button
            onClick={() => handleNextStep()}
            className="
                            inline-flex 
                            items-center 
                            justify-center
                            py-1
                            px-2 
                            w-full
                            shadow-sm 
                            uppercase
                            text-medium
                            font-medium 
                            tracking-widest
                            text-black border border-black hover:text-white hover:bg-gray-900
                            focus:outline-none 
                            focus:ring-2 
                            focus:ring-offset-2 
                            focus:ring-blue-500"
          >
            Next <ArrowRightIcon className="ml-2 h-4 w-4" />
          </button>
        </div> */}
      </div>
    )
  } else {
    return (
      <div className='text-center md:text-left'>
        {
          <div className="text-center">
            <label className="mb-5 block text-md md:text-xl font-medium text-gray-800">
              <b>Congratulations</b>, you qualify for a complimentary consultation!
            </label>
            <p>Are you ready to talk to a lawyer now?</p>
            <div className='flex flex-col'>
              <button
                onClick={() => setResults(false)}
                id="readyButton"
                className="mt-5 rounded-sm drop-shadow-md hover:drop-shadow-2xl md:px-10 px-4 py-5 bg-teal-700 text-white hover:-translate-y-1 font-extrabold text-md md:text-xl uppercase tracking-widest">
                Yes, I&apos;m ready!
              </button>
              <button
                id="notReadyButton"
                onClick={() => setResults(false)} className="mt-5 rounded-sm drop-shadow-md hover:drop-shadow-2xl md:px-10 px-4 py-5 bg-teal-700 text-white hover:-translate-y-1 font-extrabold text-md md:text-xl uppercase tracking-widest">
                I&apos;m not ready
              </button>
            </div>
          </div>
        }


      </div>
    );
  }
}


