import React, { useState } from 'react'
import { XCircleIcon, ReplyIcon, CheckCircleIcon, XIcon } from '@heroicons/react/outline';
import { supabase } from '../../../utils/supabaseClient';

export default function Step1(props) {

    const [dataArray, setDataArray] = useState(props.cancer_type)
    const [error, setError] = useState(false)

    // console.log(dataArray)

    const updateFieldValue = (name, value, name2) => {
        var arr = dataArray
        let newArr = [...arr];
        if (value === false) {
            console.log("remove value", name2)
            var filtered = newArr.filter(function (value, index, arr) {
                return value != name2;
            });
            setDataArray(filtered)
        } else {
            newArr.push(name2);
            setDataArray(newArr)
        }
    }

    const handleNextStep = () => {
        if (dataArray.length) {
            props.updateFieldValue("cancer_type", dataArray)
            props.nextStep(1)

        }
        else {
            setError(true)
        }
    }



    return (
        <div id={`${props.form}Step1`}>
            <label htmlFor={`cancer_diagnosis`} className="mb-5 block text-normal font-semibold text-black">
                {props.product === "Acetaminophen" ?
                    `Did you or a loved one take ${props.product} while pregnant? Did the child develop any of the following?` :
                    props.product == "Camp Lejeune" ? `Were you or a loved one diagnosed with any of the following after drinking the water at ${props.product}?` :
                        `Were you or a loved one impacted by  ${props.product}?`
                }
            </label>
            <div>
                {
                    props.injuries.map((injury, index) =>
                        <div key={index} className="flex items-center mb-3">
                            <div className="flex items-center h-5">
                                <input
                                    id={`${injury}${props.form}`}
                                    name={injury}
                                    type="checkbox"
                                    className={`bg-white focus:ring-teal-500 h-5 w-5 text-teal-600 ${error ? 'border-red-500' : 'border-gray-300'} rounded`}
                                    onChange={(e) => updateFieldValue('cancer_type', e.target.checked, e.target.name)}
                                    checked={dataArray.includes(injury)}
                                    defaultValue={dataArray.includes(injury)}
                                />
                            </div>
                            <div className="ml-3 text-base text-left">
                                <label htmlFor={`${injury}${props.form}`} className="font-normal text-gray-700">
                                    {injury}
                                </label>
                            </div>
                        </div>
                    )}
                <div className="flex items-center mb-3">
                    <div className="flex items-center h-5">
                        <input
                            id={`notListed${props.form}`}
                            name="Not listed"
                            type="checkbox"
                            className={`bg-white focus:ring-teal-500 h-5 w-5 text-teal-600 ${error ? 'border-red-500' : 'border-gray-300'} rounded`}
                            onChange={(e) => updateFieldValue('cancer_type', e.target.checked, e.target.name)}
                            checked={dataArray.includes("Not listed")}
                            value={dataArray.includes("Not listed")}

                        />
                    </div>
                    <div className="ml-3 text-base">
                        <label htmlFor={`notListed${props.form}`} className="font-normal text-gray-700">
                            Not listed
                        </label>
                    </div>
                </div>
                <div className="flex items-center mb-3">
                    <div className="flex items-center h-5">
                        <input
                            id={`Undiagnosed${props.form}`}
                            name="Undiagnosed"
                            type="checkbox"
                            className={`bg-white focus:ring-teal-500 h-5 w-5 text-teal-600 ${error ? 'border-red-500' : 'border-gray-300'} rounded`}
                            onChange={(e) => updateFieldValue('cancer_type', e.target.checked, e.target.name)}
                            checked={dataArray.includes("Undiagnosed")}
                            value={dataArray.includes("Undiagnosed")}
                        />
                    </div>
                    <div className="ml-3 text-base">
                        <label htmlFor={`Undiagnosed${props.form}`} className="font-normal text-gray-700">
                            Undiagnosed
                        </label>
                    </div>
                </div>
                {
                    error &&
                    <p className="mt-2 mb-0 text-xs text-red-500 flex col-span-12">
                        <XCircleIcon className="h-4 w-4 mr-1" /> Please select a value to proceed.
                    </p>
                }
            </div>
            <div className="mt-10 text-right">
                <button
                    onClick={(event) => handleNextStep(event)}
                    className="
                            inline-flex 
                            items-center 
                            justify-center
                            py-1
                            px-2 
                            w-full
                            shadow-sm 
                            uppercase
                            text-medium
                            font-medium 
                            tracking-widest
                            text-black border border-black hover:text-white hover:bg-gray-900
                            focus:outline-none 
                            focus:ring-2 
                            focus:ring-offset-2 
                            focus:ring-blue-500"
                >
                    Next
                </button>
            </div>
        </div>
    );

}

