import React from 'react';

export default function Success(props) {
  return (
    <div id={`${props.form}Success`} className='text-left'>
      <p className="p-10 text-center mb-5 block text-2xl font-bold text-black">
       Please wait.
      </p>
    </div>
  );
}
