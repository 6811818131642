import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router';

export default function Header(props) {
  const { asPath } = useRouter()

  return (
    <Head>
      <meta charSet="utf-8" />
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      <meta name="keywords" content={props.keywords} />
      {/* <!--  Essential META Tags --> */}
      <meta property="og:title" content={props.title} />
      {asPath.includes("/blog/") && <meta property="og:type" content="article" /> }
      {asPath.includes("/lawsuit/") && <meta property="og:type" content="article" /> }
      {asPath.includes("/blog/") &&  <meta property="og:image" content={props.image} /> }
      {asPath.includes("/lawsuit/") &&  <meta property="og:image" content={props.image} /> }
      <meta property="og:url" content={`https://thelegalcanary.com${asPath}`} />
      <meta name="twitter:card" content="summary_large_image" />


      {/* <!--  Non-Essential, But Recommended --> */}
      <meta property="og:description" content={props.description} />
      <meta property="og:site_name" content="thelegalcanary.com" />
      <meta name="twitter:image:alt" content={props.title} />

      {/* <!--  Non-Essential, But Required for Analytics --> */}
      {/* <meta property="fb:app_id" content="1" />
      <meta name="twitter:site" content="@" /> */}

      {/* <script
        type="text/javascript"
        src="https://app.termly.io/embed.min.js"
        data-auto-block="on"
        data-website-uuid="222a6553-0e2e-4f92-a9d9-d844ca091f4b"
      async /> */}

      <meta name="viewport" content="initial-scale=1.0, width=device-width" />

      {/* Google Search Console tag */}
      <meta name="google-site-verification" content="aigfZBx1houFR4SRJx_8cofA1HkCXc6Qa0BpSjcMezI" />
    </Head>
  );
}
